import React from 'react'
import { Link } from '../../plugins/gatsby-plugin-atollon'
import FormComponent from './form'
import useVenuesQuery from '../hooks/use-venues-query'
import { Darling, Group, Instagram, Facebook, LinkedIn, YouTube } from './icons'

const Footer = ({subscribeForm}) => {

  const venues = useVenuesQuery()

  let footerClass = 'footer'

  return (
    <footer className={footerClass}>
      <div className='footer__inner'>
        <div className='footer__darling'><Darling /></div>
        <div className='footer__venues'>
          <ul>
            { venues.map((venue, index) => {
              return (
                <li key={index}>
                  <Link to={venue.permalink}>{venue.title}</Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='footer__middle'>
          <nav className='footer__nav'>
            <ul>
              <li><Link to='/about-us/'>About</Link></li>
              <li><Link to='/careers/'>Careers</Link></li>
              <li><Link to='/contact/'>Contact</Link></li>
            </ul>
          </nav>
          { subscribeForm &&
            <div className='footer__subscribe'>
              <FormComponent form={subscribeForm} />
            </div>
          }
          <nav className='footer__social'>
            <ul>
              <li><Link to='https://www.instagram.com/darlinggroup/'><Instagram /></Link></li>
              <li><Link to='https://www.linkedin.com/company/darlinggroup/posts/?feedView=all'><LinkedIn /></Link></li>
              <li><Link to='https://www.facebook.com/darlinghospitality/'><Facebook /></Link></li>
              <li><Link to='https://www.youtube.com/@darlinggroup4472'><YouTube /></Link></li>
            </ul>
          </nav>
        </div>
        <div className='footer__credits'>
          <ul>
            <li><Link to='/terms-conditions/'>T &amp; C's</Link></li>
            <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
          </ul>
          <p>© 2024 Darling Group. All rights reserved.</p>
          <p>Site by <Link to='https://atollon.com.au?utm_source=darling-group&utm_medium=website&utm_campaign=referrer&utm_term=hospitality' target='_blank' rel='noopener noreferrer'>Atollon</Link></p>
        </div>
        <div className='footer__group'><Group /></div>
      </div>
    </footer>
  )
}

export default Footer
